import React, { useState, useEffect } from "react";
import { Card, Row, Col, Popover, OverlayTrigger, Button, Image } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { isIE } from "react-device-detect";
import { FaChevronCircleRight } from "react-icons/fa";

import Link from "../widgets/ScrollToTopLink";
import InfoIcon from "../../images/icons/info@3x.png";
import { navigate } from "gatsby";


export default function CarCard (props) {
    const { fullHeader, year, price, brand, title, linksTo, rightData, trans, kms, parameters } = props;
    /* console.log(props,"AYUDA") */
  
    /*function navegante(linksTo){
        window.scrollTo(0, 0);
        //navigate(`${linksTo}`, {state: {paramsPublicidad:parameters}} );
        navigate("/")
        console.log("Sucede lo siguiente: ", linksTo)
    }*/

    return (
        <Card 
            className="car-card shadow-sm text-primary-colorh-100" 
            onClick={() => {
                console.log("Pulsando CarCARD: ", linksTo, parameters);
                window.scrollTo(0, 0); 
                localStorage.setItem("paramsPublicidad", parameters); 
                navigate(`${linksTo}`, {state: {paramsPublicidad:parameters}} );
            }}
            //onClick={() => navegante(linksTo)}
            //to={linksTo}
            >
            <Header full={fullHeader} {...props} />
            <Card.Body>
                <Card.Title>
                <h3 className="text-carCard-titulo" style={{fontSize: 18}}>
                        {brand} {title} <span style={{backgroundColor: '#003864', borderRadius: '5px', padding: '3px', color: 'white'}}>{year}</span>
                    </h3>
                </Card.Title>
                {trans ? (
                <div>
                    <Row>
                        <Col className="text-carCard-detalle" style={{display: 'flex'}}>
                        {trans ? <h5 style={{fontSize: '1.10rem'}}>{trans}</h5> : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-carCard-detalle" style={{display: 'flex'}}>
                       
                        {trans ? <h5 style={{fontSize: '.8rem', color: 'black'}}> <NumericFormat displayType="text" value={kms} allowLeadingZeros thousandSeparator="," decimalScale={0} />kms</h5> : null}
                        </Col>
                    </Row>
                </div>
                ):(
                    <div>

                    </div>
                )}
                <div className="description">
                    {trans ? null : <i>desde:</i>}
                    $<NumericFormat displayType="text" value={price} allowLeadingZeros thousandSeparator="," decimalScale={0} />
                    <span style={{ fontSize: 8 }}> MXN</span>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Popover>
                                    <Popover.Body>
                                        Precios sujetos a cambios sin previo aviso, Aplican
                                        restricciones
                                    </Popover.Body>
                                </Popover>}
                        >
                        {({ ref, ...triggerHandler }) => (
                            <Button
                            variant="white"
                            {...triggerHandler}
                            className="d-inline-flex align-items-center"
                            >
                            <Image
                                ref={ref}
                                roundedCircle
                                src={InfoIcon}
                                style={{ width: "20px" }}
                            />
                            </Button>
                        )}
                    </OverlayTrigger>
                </div>
            </Card.Body>
            <Card.Footer className="bg-white">
                <Row>
                    <span className="text-right text-secundario" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <h6 style={{marginBottom: 0, fontWeight: '400', fontSize: 12, marginRight: 5}}> Ver más de tu auto{trans ? "" : " nuevo"} </h6>
                        <div style={{color: "#007bff"}}> <FaChevronCircleRight size={12}/> </div>
                    </span>
                </Row>
            </Card.Footer>
        </Card>
    )

}

function Header({image, year, model, brand, price, trans}){
    const [isLoaded, setIsLoaded] = useState(false);

    const webp300 = image ? image.replace(".png", "@300w.webp") : "";
    const webp600 = image ? image.replace(".png", "@600w.webp") : "";
    const png600 = image ? image.replace(".png", "@600w.png") : "";
    const png300 = image ? image.replace(".png", "@300w.png") : "";
    
    if(trans){
        return(
            <Card.Header style={{backgroundColor: '#343a40', padding: 0, height: 219, overflow: 'hidden'}}>
                <picture>
                    <img
                        loading="lazy"
                        src={image}
                        alt={ brand + ` ` + model + ` ` + year + ` $` + price + ` - Agencia Chevrolet - Seminuevos a crédito`}
                        style={{width: '100%'}}
                    />
                </picture>
            </Card.Header>
        )
    } else {
        return(
            <Card.Header style={{backgroundColor: '#343a40', padding: 0, height: 219, overflow: 'hidden'}}>
                <picture>
                    <source srcSet={`${webp300} 1.0x, ${webp600} 2.0x`} type="image/webp"/>
                    <source srcSet={`${png300} 1.0x, ${png600} 2.0x`} type="image/png" />
                    <img
                        loading="lazy"
                        src={image}
                        alt={ brand + ` ` + model + ` ` + year + ` $` + price + ` - Agencia Chevrolet - Crédito de auto`}
                        style={{width: '100%'}}
                    />
                </picture>
            </Card.Header>
        )
    }

}