import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../components/layouts/layout";
import Seo from "../../components/seo";
import FiltroSeminuevos from "../../components/lateral/filtro-seminuevos";
import CatalogoLayoutSeminuevos from "../../components/layouts/CatalogoLayoutSeminuevos";
import api from "../../actions/riveroApi";

import { Container, Col, Row } from "react-bootstrap";

export default function SeminuevosIndex (props){
    console.log("CHEVROLET")
    const parameters = props.location.search;

    const [ marcas, setMarcas ] = useState();
    const [ semiCars, setSemiCars ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ seleccionTipos, setSeleccionTipos ] = useState([]);

    const [ filterTipos, setFilterTipos ] = useState([]);
    const [ filterMarcas, setFilterMarcas ] = useState([]);
    const [ filterSearch, setFilterSearch ] = useState();

    let arrTipos = [];
    let arrMarcas = [];

    useEffect(() => {
        setIsLoading(true);
        getSeminuevosCarros();
        getMarcasCarros();
    }, [filterTipos, filterMarcas, filterSearch]);

    function getSeminuevosCarros(e){
        
        const options = {
            filterSearch:  filterSearch,
            filterMarcas: filterMarcas,
            filterTipos: filterTipos,
            orderBy: "precio:asc",
            perPage: 100
        }

        api.getSeminuevosCars(options).then((resp) => {
            setIsLoading(false);
            const filterPicure = resp.data.filter((obj) => { return obj.picture !== null; })

            //arrayCarros.push(filterPicure);
            const posts = filterPicure.filter((p) => p.precio > 0);

            for(var l = 0; l < posts.length; l++){
                posts[l].auto = "Seminuevos";
                posts[l].slug = `${posts[l].marca.toLowerCase()}-${posts[l].nombre.toLowerCase().split(" ").join("-")}-${posts[l].year}-${posts[l].id}`;
                posts[l].price = posts[l].precio;
                //console.log(posts[l]);
            }
        
            setSemiCars(posts);

        }).catch((error) => console.log(error));
    }

    function getMarcasCarros(){
        api.getMarcasCars().then((resp) => {
            setMarcas(resp.data);
        }).catch((error) => console.log(error));
    }
      
    const onFilter = useCallback((data, proviene) => {

        //console.log("Sucede lo siguiente: ", data, proviene)
        if(proviene === "Marcas"){
            arrMarcas.indexOf(data) === -1 ? arrMarcas.push(data) : arrMarcas.splice(arrMarcas.indexOf(data),1);
            setFilterMarcas(filterMarcas.concat(arrMarcas));
            //console.log("Estamos en el onFilter: ", arrMarcas);

        } else if(proviene === "Tipos") {
            let separar = data.split(" ");
            arrTipos.indexOf(separar[0].toLowerCase()) === -1 ? arrTipos.push(separar[0].toLowerCase()) : arrTipos.splice(arrTipos.indexOf(separar[0].toLowerCase()),1);
            setFilterTipos(filterTipos.concat(arrTipos));

        } else if (proviene === "Buscador"){
            setFilterSearch(data);
        }

    },[]) 

    console.log("Tengo arrayTipos: ", filterTipos);
    return(
        <Layout selectedMenuItem={"seminuevos"} parameters={parameters}>
            <Seo
                title="Grupo Rivero - Seminuevos Monterrey"
                description="Seminuevos - Autos Seminuevos Agencia - Seminuevos Monterrey - Autos Seminuevos Credito - carros seminuevos - Grupo Rivero Seminuevos - Nissan Seminuevos - Aveo, Beat, Spark, Cavalier, Trax, March, Sentra, Kicks, Xtrail"
                keywords="Seminuevos, Seminuevos chevrolet, autos seminuevos, seminuevos monterrey, seminuevos guadalupe, carros seminuevos, nissan seminuevos"
            />
            <Container fluid className="container-layout-primero" style={{maxWidth: '100%'}}>
                <Row>
                    <Col xl={2} lg={3} md={3} sm={5} className="p-3 pt-3">
                        <FiltroSeminuevos marcas={marcas} filterMarcas={filterMarcas} filterSearch={filterSearch} onFilter={onFilter} seleccionTipos={seleccionTipos}/>
                    </Col>
                    <Col xl={10} lg={9} md={9} sm={7} className="p-3 pt-3">
                        <CatalogoLayoutSeminuevos post={semiCars} isLoading={isLoading} parameters={parameters}/>
                    </Col>
                </Row>
                <h1 style={{ fontSize: 15, color: "gray" }}>
                    Agencia Chevrolet Monterrey
                </h1>
                <h2 style={{ fontSize: 12, color: "gray" }}>
                    Encuentra tu auto nuevo con Grupo Rivero Agencia Chevrolet!
                </h2>
                <h5 style={{ fontSize: 10, color: "gray" }}>Buscar tú auto nuevo</h5>
            </Container>
        </Layout>
    )

}