import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaQuoteLeft } from "react-icons/fa";

import SEO from "../../components/seo";
import api from "../../actions/riveroapi";
import Layout from "../../components/layouts/layout";
import Image from "../../components/widgets/basico/image";
import { Separador } from "../../components/widgets/miscelaneos";

export default function QuienesSomosNissan(props){

    const parameters = props.location.search;

    const [ data, setData ] = useState();

    useEffect(() => {
        api.getTestimonios().then((resp) => setData(resp.data)).catch((error) => console.log(error));
    }, [])

    const quienesSomos = [
        {titulo: "NUESTRA MISIÓN", img: "mision-nissan-rivero.jpg", descripcion: "Si no apoyamos a la gente de manera transparente, en la compra o cuidado de su auto,van a batallar. Por eso, en Grupo Rivero lo hacemos con un equipo de gente buena y tecnología, para brindarte una experiencia de primera y que más familias vivan mejor."},
        {titulo: "QUIENES SOMOS", img: "quienes-somos-nissan-rivero.jpg", descripcion: "Grupo Rivero es una empresa de agencias automotrices, estamos conformados por un equipo de gente buena apasionada por servir al cliente de manera transparente, hablando siempre con la verdad y buscando el bien común para que más familias vivan mejor."}
    ];

    const agencias = [
        {titulo: "NISSAN RIVERO LAS TORRES", img: "sucursal-nissan-rivero-las-torres.jpg"},
        {titulo: "NISSAN RIVEO CONTRY", img: "sucursal-nissan-rivero-contry.jpg"},
        {titulo: "NISSAN RIVERO VALLE", img: "sucursal-nissan-rivero-valle.jpg"},
    ];

    const divisiones = [
        {titulo: "Rivero Renta", img: "logo-rentas.png", url: "https://riverorenta.com", descripcion: "Rivero Renta, es una solución en renta de automóviles, pick-up, camiones, camionetas de carga y pasajeros."},
        {titulo: "Transportes Rivero", img: "logo-transportes.png", url: "https://transportes.gruporivero.com", descripcion: "Transportes Rivero resuelve las necesidades de transporte de las empresas de Monterrey. Contamos con un equipo altamente capacitado y unidades con tecnología de vanguardia que nos permiten cumplir nuestro objetivo."},
        {titulo: "Maquinaria Rivero", img: "logo-maquinaria.png", url: "https://maquinariarivero.com", descripcion: "En Maquinaria Rivero nos dedicamos a la venta, renta, financiamiento, servicio y refacciones de maquinaria pesada, montacargas y plataformas de elevación, para brindarte un excelente servicio de acuerdo a tus necesidades."}
    ]

    const mappingQuienesSomos = quienesSomos.map((obj, i) => {
        return(
            <Col key={i}>
                {obj.titulo === "NUESTRA MISIÓN" ? (
                    <Col className="row p-2 m-0">
                        <Col md={6} className="text-center">
                            <div className="m-0">
                                {<Image fileName={obj.img} alt={obj.titulo}/> }
                            </div>
                        </Col>
                        <br />
                        <Col md={6} className="text-center">
                            <h1 className="titulo-quienes-somos mt-4">{obj.titulo}</h1>
                            <Separador/>
                            <p className="p-2 texto-quienes-somos">{obj.descripcion}</p>
                        </Col>
                    </Col>
                ) : (
                    <Col className="row p-2 m-0">
                        <Col md={6} className="text-center">
                            <h1 className="titulo-quienes-somos mt-4">{obj.titulo}</h1>
                            <Separador/>
                            <p className="p-2 pb-4 texto-quienes-somos">{obj.descripcion}</p>
                        </Col>
                        <br />
                        <Col md={6} className="text-center">
                            <div className="m-0">
                                {<Image fileName={obj.img} alt={obj.titulo}/>}
                            </div>
                        </Col>
                    </Col>
                )}
            </Col>
        )
    });

    const mappingSucursales = agencias.map((obj, i) => {
        return(
            <Col lg={6} md={6} className=" p-2 m-0" key={i}>
                { <Image fileName={obj.img} alt={obj.titulo}/> }
                <p>{obj.titulo}</p>
            </Col>
        )
    })

    const mappingDivisiones = divisiones.map((obj, i) => {
        return(
            <Col lg={4} className="p-4" key={i}>
                <div style={{marginBottom: '1em'}}>
                    <Image fileName={obj.img} alt={obj.titulo}/>
                </div>
                <div className="p-2 pb-4"><p className="texto-quienes-somos">{obj.descripcion}</p></div>
                <a href={obj.url} target="_blank" rel="noreferrer">
                    <Button className="btn-oscuro">Descubre Más</Button>
                </a>
            </Col>
        )
    })

    const mappingTestimonios = data?.map((obj, i) => {
        return(
            <Col lg={6} md={12} key={i}>
                <div className="linea-quienes-somos" style={{width:'100%',height:'95%'}}>
                    <FaQuoteLeft className="icon-quote-quinesSomos"/>
                    <p className="mt-4 mb-4">{obj.testimonio}</p>
                    <p className="text-primary font-weight-bolder">{obj.nombre}</p>
                </div>
            </Col>
        )
    })

    return(
        <Layout selectedMenuItem={"quienes-somos"} parameters={parameters}>
            <SEO
                title="Grupo Rivero - Agencia Chevrolet - Agencia Nissan - Quiénes somos"
                description="Grupo Rivero - Agencia Chevrolet - Chevrolet Monterrey, Chevrolet Linda Vista, Chevrolet Guadalupe, Chevrolet Santa Catarina, Nissan Contry, Nissan Allende, Humbrto Lobo - Aveo, Beat, Spark, Onix, March, Sentra, Kicks, Xtrail"
                keywords="Agencia Chevrolet, Agencia Nissan, Agencia de autos, chevrolet monterrey, nissan monterrey, Chevrolet Linda Vista, Chevrolet Guadalupe, Chevrolet Santa catarina, Nissan Contry, Nissan Allende, Aveo, Beat, Spark, Onix, March, Sentra, Kicks, Xtrail"
                path="/quienes-somos"
            />
            <div className="banner-quienes-somos-nissan">
                <div className="sombra-banner-quienes-somos">
                    <div className="mt-4 p-4">
                        <p className="mb-2">Bienvenidos a</p>
                        <h1 className="titulo-quienes-somos">GRUPO RIVERO</h1>
                        <div className="mt-4 mb-4"><Separador/></div>
                        <br></br>
                        <p className="texto-quienes-somos">La más grande e importante agencia Nissan en Monterrey. Nos especializamos en dar soluciones en cuestiones de crédito, confort y movilidad, de forma fácil, rápida y segura.</p>
                    </div>
                </div>
            </div>
            <Container className="bg-white p-4">
                {mappingQuienesSomos}
                <br/>
                <Col className="text-center pt-4">
                    {/* <p className="p-2">Llevamos más de 30 años en el mercado, manejando exclusivamente las marcas de General Motors, Chevrolet, Cadillac, Buick, GMC y con la representación de Performance Store con las marcas deportivas Corvette y Camaro, contamos con 5 sucursales en Monterrey y su área metropolitana.</p> */}
                </Col>
                <Row className="p-4">
                    {mappingSucursales}
                </Row>
                <br/>
                <Row>
                    {mappingDivisiones}
                </Row>
                <hr />
                <div style={{backgroundColor: 'saddlebrown'}}>
                    <Image fileName="banner-nissan-fleet-solutions.jpg" alt="Flotillas"/>  
                </div>
                <hr />
                {/* <Row>
                    <center>
                        <h3 className="titulo-quienes-somos">Lo que dice la gente</h3>
                        <Separador/>
                    </center>
                </Row>
                <Row>
                    <Col lg={4} md={4} sm={12}>
                        <Image fileName="primo-opinion-gris.png" alt="Primo Rivero"/>
                    </Col> 
                    <Col lg={8} md={8} sm={12} className="p-3">
                        <Row>
                            {mappingTestimonios}
                        </Row>
                    </Col>
                </Row> */}
                <br/>
                <br/>
                <br/>
                <br/>
            </Container>
        </Layout>
    )
    
}