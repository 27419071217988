import React from "react";
import { Container, Row, Col} from "react-bootstrap";

import SEO from "../../components/seo";
import Layout from "../../components/layouts/layout";
import Image from "../../components/widgets/basico/image";
import FormularioTallerServicio from "../../components/widgets/formularios/FormularioTallerServicio";
import CatalogoPromos from "../../components/layouts/CatalogoPromos";

export default function TallerServicio(props){

    const parameters = props.location.search;

    return(
        <Layout selectedMenuItem="taller-servicio" parameters={parameters}>
            <SEO
                title="Servicio Chevrolet - Taller Chevrolet - Taller Nissan - Servicio Nissan - Taller Grupo Rivero Servicio"
                description="Grupo Rivero - Servicio Chevrolet - Taller Chevrolet - Servicio Nissan - Taller Nissan - Agencia Chevrolet Mty - Agencia Nissan Mty - Servicio Chevrolet Costos - Servicio Aveo, Beat, Spark, Onix,  March, Sentra, Kicks, Xtrail, Cavalier, GM, Buick, Cadillac"
                keywords="Servicio Chevrolet, taller de servicio automotriz, taller chevrolet, servicio chevrolet, taller Nissan, Servicio nissan"
                path="/taller-de-servicio"
            />
            <Container>
                <div className="bg-white">
                    <Row style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <Col lg={4} md={4} sm={12} className="pb-4">
                            <Container>
                                <h1 className="p-4" style={{ fontSize: 25 }}>Cita en taller de servicio</h1>
                                <Row>
                                    <Col>
                                        <FormularioTallerServicio/>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg={8} md={8} sm={12}>
                            <Image fileName="rivero-taller-de-servicio-chevrolet.jpg" alt="Taller de servicio"/>
                            <h1 style={{ fontSize: 25 }} className="p-4">
                                Taller de Servicio Chevrolet, GMC, Buick, Cadillac y Corvette
                            </h1>
                            <div className="p-4">
                                <p>
                                En Grupo Rivero contamos con personal certificado por GM que
                                garantiza tu servicio o reparación con piezas originales.
                                </p>
                                <p>
                                Para nosotros tú y tu familia son lo más importante, por eso
                                te ofrecemos:
                                </p>
                                <ul>
                                <li>
                                    Agendar fácilmente tu cita y cuidar los tiempos de entrega
                                    de tu auto
                                </li>
                                <li>Darte la mejor mano de obra certificada.</li>
                                <li>
                                    Reparar o dar servicio a tu unidad con el mejor equipo y
                                    tecnología
                                </li>
                                <li>Cuidar siempre la garantía de tu auto</li>
                                </ul>
                                <p>Agenda tu cita de servicio con nosotros</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="pt-4">
                        <Col className="px-5">
                            <h4>Promociones</h4>
                            <center>
                                <CatalogoPromos promoType="taller" props={props}/>
                            </center>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Layout>
    )

}