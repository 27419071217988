import React, { useState, useEffect } from "react"
import Layout from "../components/layouts/layout"
import BannerImage from "../components/widgets/BannerImage";
import { Container, Row, Col, Button } from "react-bootstrap";
import SEO from "../components/seo";
import { withPrefix } from "gatsby";
import { navigate, Link } from "gatsby";

import Image from "../components/widgets/basico/image";

import btnNuevos from "../images/icons/btn_nuevos.png";
import btnNuevosAct from "../images/icons/btn_nuevos_act.png";
import btnSeminuevos from "../images/icons/btn_seminuevos.png";
import btnSeminuevosAct from "../images/icons/btn_seminuevos_act.png";
import btnCompramos from "../images/icons/btn_compramos.png";
import btnCompramosAct from "../images/icons/btn_compramos_act.png";
import btnTaller from "../images/icons/btn_taller.png";
import btnTallerAct from "../images/icons/btn_taller_act.png";
import carroNuevo from "../images/nuevo-carro.png";
import btnChevy from "../images/Nuevo/Principal/img_chevy.png";
import btnChev from "../images/Nuevo/Principal/b_chevrolet.png";
import btnNissan from "../images/Nuevo/Principal/b_nissan.png";

import btnBuick from "../images/Nuevo/Principal/b_buick_new.png";
import btnCadillac from "../images/Nuevo/Principal/b_cadillac_new.png";
import btnGMC from "../images/Nuevo/Principal/b_gmc_new.png";

import btnBuickMovil from "../images/Nuevo/Principal/b_buick_movil.png";
import btnCadillacMovil from "../images/Nuevo/Principal/b_cadillac_movil.png";
import btnGMCMovil from "../images/Nuevo/Principal/b_gmc_movil.png";
import { globalHistory } from "@reach/router";


import btnSemis from "../images/Nuevo/Principal/img_b_seminuevos.jpg";
import btnTallerHome from "../images/Nuevo/Principal/img_b_taller.jpg";
import btnVendeTuAuto from "../images/Nuevo/Principal/b_vende.png";
import btnVerSemis from "../images/Nuevo/Principal/b_ver_seminuevos.png";
//import fondoMovil4 from "../images/Nuevo/Principal/FondosMovil/f-movil-4.jpg";
import todosSomos from  "../images/Nuevo/Principal/todos_primos.jpg";
import semisMovil from "../images/Nuevo/Principal/tira_seminuevos_movil.png";
import tallerMovil from "../images/Nuevo/Principal/tira_taller_movil.png";
import btnHacerCita from "../images/Nuevo/Principal/b_cita.png";

///////////////////// HOME PAGE //////////////////////

const IndexPage = (props) => {
  const parameters = props.location.search;

  const [ alea, setAlea ] = useState();
  const [ aleaNissan, setAleaNissan ] = useState();
  const [ aleaMovil, setAleaMovil ] = useState();
  //const [ backMovil, setbackMovil ] = useState(fondoMovil4);

  function navegante(marca){
    // Guardar la marca y los params de publicidad en localStorage
    globalHistory.location.pathname.split("");
    var marcaDeseada = globalHistory.location.pathname.split("/");
    //console.log(marca,"AJDSAJ")
    /* localStorage.setItem('ultimaMarcaSeleccionada', marca);
    localStorage.setItem('paramsPublicidad', parameters); */
    if(marca === "NISSAN") {
      navigate(`https://nissanrivero.com/${marca.toLowerCase()}/catalogo/nuevos/` + parameters,{state: {marcaHome: marca}});
    } else {
      navigate(`https://chevroletrivero.com/${marca.toLowerCase()}/catalogo/nuevos/` + parameters,{state: {marcaHome: marca}});
    }
  }

  useEffect(() => {
      const number = [0,1,2,3,4,5,6,7,8,9];
      const numberNissan = [10,11];

      const numberBannerMovil = [ 0, 1 ];

      var aleatorio = parseInt(Math.random() * (number.length));
      var aleatorioNissan = parseInt(Math.random() * (numberNissan.length));
      var aletatorioMovil = parseInt(Math.random() * (numberBannerMovil.length));

      var aleatorioFondo = `f-movil-${aletatorioMovil}.jpg`;

      setAlea(1)
      setAleaNissan(10)
      setAleaMovil(aleatorioFondo);
      
  }, []);
  //console.log("Aleatorio de nissan: ", aleaMovil);
  return (
    <Layout parameters={parameters}>
      <SEO
        title="Grupo Rivero Chevrolet Nissan Monterrey - Agencia Autos Nuevos y Seminuevos"
        description="Grupo Automotriz - Agencias Chevrolet y Nissan Monterrey. Apoyamos a la gente de manera transparente, para que estrenes tu carro nuevo o seminuevo. Chevrolet, Nissan, Cadillac, Buick, GMC. Aveo, Beat, Onix, Tracker, Sentra, March, Altima, Kicks, XTrail"
        keywords="Grupo Rivero, agencia chevrolet, agencia nissan, autos seminuevos, carro nuevo, carro chevrolet, carro nissan, estrena carro"
      />
      <div className="fullContain">
        <Container className="d-lg-block d-md-block d-none" >
   
          <Row className="d-lg-flex d-md-flex d-none" style={{ position: "relative", marginBottom: '10px'}}>
            
            <Col lg={12} md={12} sm={12} xs={12}>
              <div style={{height: '100%', width: '100%', marginTop: '-65px'}}>
                <BannerImage alea={alea} props={props} />  
              </div>
            </Col>

            <div style={{ position: "absolute",  zIndex: 1}}>

            <h1 style={{color:'white', fontFamily: 'Arial', textAlign: 'center', fontSize: '1.2em', textShadow: '2px 2px black', marginTop: '15px'}}>DA CLIC EN TU MARCA FAVORITA</h1>
              <Row className="botones-superiores-home ">
                <Col lg={6} md={6} sm={6} xs={6}>
                  <div className="banner-container-botones">
                    <center>
                      <div
                        className="btn-chev-home pt-3 pb-3"
                        onClick={() => navegante("CHEVROLET")}
                      >
                        <img className="btnChevHome" src={btnChev} />
                      </div>
                    </center>
                  </div>
                </Col>

                <Col lg={6} md={6} sm={6} xs={6}>
                  <div className="banner-container-botones">
                    <center>
                      <div
                        className="btn-nissan-home pt-3 pb-3"
                        onClick={() => navegante("NISSAN")}
                      >
                        <img className="btnNissanHome" src={btnNissan} />
                      </div>
                    </center>
                  </div>
                </Col>
              </Row>

{/*             <Row>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Row>
                  <Col lg={9} md={9} sm={9} xs={9} className="dosBoton1">
                    <div className="banner-container-botones">
                      <center>
                        <div
                          className="btn-chev-home pt-3 pb-3"
                          onClick={() => navegante("CHEVROLET")}
                        >
                          {" "}
                          <img className="btnChevHome" src={btnChev} />
                        </div>
                      </center>
                    </div>
                  </Col>

                  <Col lg={3} md={3} sm={3} xs={3} className="dosBoton2">
                    <div className="banner-container-botones">
                      <center>
                        <div
                          className="btn-buick-home"
                          onClick={() => navegante("BUICK")}
                        >
                          <img className="btnOtrosHome" src={btnBuick} />
                        </div>
                      </center>
                      <center>
                        <div
                          className="btn-cadillac-home"
                          onClick={() => navegante("CADILLAC")}
                        >
                          {" "}
                          <img className="btnOtrosHome" src={btnCadillac} />
                        </div>
                      </center>
                      <center>
                        <div
                          className="btn-gmc-home"
                          onClick={() => navegante("GMC")}
                        >
                          {" "}
                          <img className="btnOtrosHome" src={btnGMC} />
                        </div>
                      </center>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="banner-container-botones">
                  <center>
                    <div
                      className="btn-nissan-home pt-3 pb-3"
                      onClick={() => navegante("NISSAN")}
                    >
                      <img className="btnNissanHome" src={btnNissan} />
                    </div>
                  </center>
                </div>
              </Col>
            </Row> */}
            <div className="div-frase-principal">
              <h1 className="m-0" style={{color:'white', fontFamily: 'Narrow', textAlign: 'center', textShadow: '1px 2px black', fontSize: '3em'}}>¡HOY ES EL DÍA DE ESTRENAR</h1>
              <h1 className="m-0" style={{color:'white', fontFamily: 'Narrow', textAlign: 'center', textShadow: '1px 2px black', fontSize: '3em'}}>TU NUEVO CARRO!</h1>
            </div>

            <div className="div-botones-lujo d-flex justify-content-center">
              <Row style={{width: '80%'}}>
                <Col lg={4} md={4} sm={4} xs={4}>
                  <div className="banner-container-botones">
                    <center>
                      <div
                        className="btn pt-3 pb-3"
                        onClick={() => navegante("BUICK")}
                      >
                        <img height={50} src={btnBuick} />
                      </div>
                    </center>
                  </div>
                </Col>

                <Col lg={4} md={4} sm={4} xs={4}>
                  <div className="banner-container-botones">
                    <center>
                      <div
                        className="btn pt-3 pb-3"
                        onClick={() => navegante("GMC")}
                      >
                        <img height={50} src={btnGMC} />
                      </div>
                    </center>
                  </div>
                </Col>

                <Col lg={4} md={4} sm={4} xs={4}>
                  <div className="banner-container-botones">
                    <center>
                      <div
                        className="btn pt-3 pb-3"
                        onClick={() => navegante("CADILLAC")}
                      >
                        <img height={50} src={btnCadillac} />
                      </div>
                    </center>
                  </div>
                </Col>
              </Row>
            </div>


            </div>
          </Row>

          

          <Row className="d-lg-flex d-md-flex d-none mt-5 mb-5">
            <Col lg={6} md={6} sm={6} xs={6}>
              <div className="banner-container-tira-seminuevos d-md-flex">
                <center>
                  <Link to={"/catalogo/seminuevos/" + parameters}>
                    <img
                      style={{
                        height: "22px",
                        marginLeft: '30px',
                        marginBottom: "5px",
                        cursor: "pointer",
                      }}
                      src={btnVerSemis}
                    />
                  </Link>
                  <br />
                  <Link to={"/valua-tu-carro/" + parameters}>
                    {" "}
                    <img
                      style={{ height: "22px",  marginLeft: '30px', cursor: "pointer" }}
                      src={btnVendeTuAuto}
                    />{" "}
                  </Link>
                </center>
              </div>
            </Col>

            <Col lg={6} md={6} sm={6} xs={6}>
              <div className="banner-container-tira-taller">
                <Link to={"/servicios-de-taller/" + parameters}>
                  <center>
                    <img
                      style={{
                        height: "22px",
                        marginBottom: "5px",
                        visibility: "hidden",
                      }}
                      src={btnVerSemis}
                    />
                    <br />
                    <img
                      style={{
                        height: "22px",
                        cursor: "pointer",
                        visibility: "hidden",
                      }}
                      src={btnVendeTuAuto}
                    />
                  </center>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="d-lg-none d-md-none d-flex px-0">
          <div className="container-zoom-movil">
            <div class="zoom-in-zoom-out">
              <Image
                fileName={`${aleaMovil}`}
                alt="banner Image Home"
                style={{height: '100%'}}
              />
            </div>
          </div>
          <Container style={{width: '100%', position: 'absolute'}}>

            <Row className="pb-2">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="div-slogan-movil">
                  <h1 className="slogan-movil">¡HOY ES EL DÍA DE</h1>
                  <h1 className="slogan-movil">ESTRENAR TU</h1>
                  <h1 className="slogan-movil">NUEVO CARRO!</h1>
                  <h3 className="m-0" style={{color:'white', fontFamily: 'Arial', fontSize: '.8em', textShadow: '2px 1px black'}}>Da clic en tu marca favorita</h3>
                </div>
              </Col>
            </Row>

            <Row className="pb-2">
              <Col lg={6} md={6} sm={6} xs={6}>
                <div>
                  <center>
                    <div
                      className="btn-chev-home-movil"
                      onClick={() => navegante("CHEVROLET")}
                    >
                      {" "}
                      <img className="btnChevHomeMovil" src={btnChev} />
                    </div>
                  </center>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <div>
                  <center>
                    <div
                      className="btn-nissan-home-movil"
                      onClick={() => navegante("NISSAN")}
                    >
                      <img className="btnNissanHomeMovil" src={btnNissan} />
                    </div>
                  </center>
                </div>
              </Col>
            </Row>

            <Row className="pb-2">
              <Col lg={4} md={4} sm={4} xs={4}>
                <div>
                  <center>
                    <div
                      className="btn-buick-home-movil"
                      onClick={() => navegante("BUICK")}
                    >
                      {" "}
                      <img className="btnOtrosHomeMovil" src={btnBuickMovil} />
                    </div>
                  </center>
                </div>
              </Col>

              <Col lg={4} md={4} sm={4} xs={4}>
                <div>
                  <center>
                    <div
                      className="btn-cadillac-home-movil"
                      onClick={() => navegante("CADILLAC")}
                    >
                      <img
                        className="btnOtrosHomeMovil"
                        src={btnCadillacMovil}
                      />
                    </div>
                  </center>
                </div>
              </Col>

              <Col lg={4} md={4} sm={4} xs={4}>
                <div>
                  <center>
                    <div
                      className="btn-gmc-home-movil"
                      onClick={() => navegante("GMC")}
                    >
                      <img className="btnOtrosHomeMovil" src={btnGMCMovil} />
                    </div>
                  </center>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className="d-lg-none d-md-none d-block">
          <Row className="p-3 div-tira-seminuevos">
            <Col className="d-flex align-items-center" lg={6} md={6} sm={6} xs={6} >
              <div className="tira-seminuevos-movil">
                <div className="semisMovil">
                  <h1 className="textoTiras" style={{color:'white'}}>¿BUSCAS UN CARRO</h1>
                  <h1 className="textoTiras2" style={{color:'white'}}>SEMINUEVO?</h1>
                </div>
              </div>
            </Col>

            <Col lg={6} md={6} sm={6} xs={6}>
              <center>
                <div className="tira-seminuevos-movil">
                  <Link to={"/catalogo/seminuevos/" + parameters}>
                    <div className="btn-tira-movil m-1">
                      <Image
                        fileName="b_ver_seminuevos.png"
                        alt="Banner Seminuevos"
                      />
                    </div>
                  </Link>
                  <Link to={"/valua-tu-carro/" + parameters}>
                    <div className="btn-tira-movil m-1">
                      <Image
                        fileName="b_vende.png"
                        alt="Banner Seminuevos"
                      />
                    </div>
                  </Link>
                </div>
              </center>
            </Col>
          </Row>

          <Row className="p-3 div-tira-taller">
            <Col className="d-flex align-items-center" lg={6} md={6} sm={6} xs={6} >
              <div className="tira-taller-movil">
                <h1 className="textoTiras" style={{color:'white'}}>AGENDA TU CITA DE</h1>
                <h1 className="textoTiras2" style={{color:'white'}}>SERVICIO</h1>
              </div>
            </Col>

            <Col className="d-flex justify-content-center align-items-center" lg={6} md={6} sm={6} xs={6} >
              <div className="tira-taller-movil">
                <Link to={"/servicios-de-taller/" + parameters}>
                  <img className="btn-tira-movil" src={btnHacerCita} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default IndexPage

